const PageRegistry = [
  {
    title: 'Overview',
    pathName: 'overview',
    spaId: 'tdp-overview-spa',
    visibleCondition: () => true
  },
  {
    title: 'Workflows',
    pathName: 'workflow-details',
    spaId: 'pacman-workflow-details-spa',
    visibleCondition: ({ isWorkflow }) => !isWorkflow
  },
  {
    title: 'Builds',
    pathName: 'builds',
    spaId: 'tdp-build-page',
    visibleCondition: () => true
  },
  {
    title: 'Elevations',
    pathName: 'elevations',
    spaId: 'scale-svcmgmt-spa',
    visibleCondition: ({ isSpa }) => !isSpa
  },
  {
    title: 'Elevations',
    pathName: 'elevations',
    spaId: 'spa-control-spa',
    visibleCondition: ({ isSpa }) => Boolean(isSpa)
  },
  {
    title: 'Actions',
    pathName: 'actions',
    spaId: 'dobby-actions-page',
    visibleCondition: () => true
  },
  {
    title: 'Clusters',
    pathName: 'clusters',
    spaId: 'scale-svcmgmt-spa',
    visibleCondition: () => true
  },
  {
    title: 'Security',
    pathName: 'security',
    spaId: 'security-portal-spa',
    visibleCondition: () => true
  }
]

export default PageRegistry
