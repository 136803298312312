import * as React from 'react'
import cx from 'classnames'
import { BanquetLoader } from 'banquet-runtime-modules'
import { useQuery } from 'react-query'
import { TabList, Tab, Tabs } from '@toasttab/buffet-pui-tabs'
import { Route, useHistory, useLocation, useParams } from 'react-router-dom'
import { ScreenSize } from '@toasttab/use-screen-size'
import PageRegistry from '../PageRegistry'
import { fetchAllRepositories } from '../queries'
import Loading from '../components/Loading'
import GithubLogo from '../images/GitHub-Mark-120px-plus.png'
import { IconLinkButton } from '@toasttab/buffet-pui-buttons'

export function RepoDetailsTabs() {
  const { repoName } = useParams()
  const location = useLocation()
  const history = useHistory()
  const { data: repo, isLoading, isError, error } = useRepoDetails(repoName)

  if (!repoName) {
    return <></>
  }

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    return <p>An error happened: {JSON.stringify(error)}</p>
  }

  const filteredPages = PageRegistry.filter((page) =>
    page.visibleCondition({
      isSpa: Boolean(repo?.topics?.includes('tadpole-spa')),
      isWorkflow: !location.pathname.includes('workflow-details')
    })
  )

  const urlPageIndex = filteredPages.findIndex((page) =>
    location.pathname.startsWith(`/${repoName}/${page.pathName}`)
  )

  return (
    <Tabs
      className='w-full mb-4 md:mb-0'
      style={{ height: 'calc(3.125rem - 1px)' }}
      defaultIndex={urlPageIndex}
      onChange={(index) => {
        const pathName = filteredPages[index].pathName
        history.push(`/${repoName}/${pathName}`)
      }}
    >
      <TabList className='w-full px-4 md:border-none md:px-0'>
        {filteredPages.map(({ title, pathName, spaId }) => (
          <Tab data-path={pathName} key={`tab-${pathName}-${spaId}`}>
            {title}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  )
}

const GithubIcon = ({ className }) => {
  return (
    <div className={cx('inline-block', className)}>
      <img
        src={GithubLogo}
        alt='View Github repository'
        className='h-6 p-0.5'
      />
    </div>
  )
}

export function RepoDetailsPage({ username, size }) {
  const { repoName } = useParams()
  const { data: repo, isLoading, isError, error } = useRepoDetails(repoName)

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    return <p>An error happened: {JSON.stringify(error)}</p>
  }

  const filteredPages = PageRegistry.filter((page) =>
    page.visibleCondition({
      isSpa: Boolean(repo?.topics?.includes('tadpole-spa'))
    })
  )
  return (
    <>
      <div data-tdp-list>
        <div className='flex items-center justify-between w-full'>
          <div className='px-4 pb-3 type-headline-4 md:type-headline-2 md:px-0'>
            {repoName}
          </div>
          <div className='flex items-center'>
            {!isLoading && repo && (
              <IconLinkButton
                icon={<GithubIcon />}
                href={`https://${repo.gitHubHost || 'github.com'}/${
                  repo.fullName
                }`}
                target='_blank'
                rel='noreferrer'
              />
            )}
          </div>
        </div>
        {size < ScreenSize.MD && <RepoDetailsTabs />}
      </div>
      {filteredPages.map(({ pathName, spaId, title }) => (
        <Route
          path={`/:repoName/${pathName}`}
          key={`tab-panel-${pathName}-${spaId}-${title}`}
        >
          <BanquetLoader
            LoadingComponent={Loading}
            name={spaId}
            username={username}
          />
        </Route>
      ))}
    </>
  )
}

function useRepoDetails(repoName) {
  const { data, ...rest } = useQuery(
    ['fetchAllRepositories'],
    fetchAllRepositories,
    { enabled: Boolean(repoName) }
  )

  return {
    data: data?.find((repo) => repo.name === repoName),
    ...rest
  }
}
