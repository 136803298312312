import { securedFetch } from '@local/jwt-manager'

export const checkResponse = (response) => {
  if (response.redirected && !response.ok) {
    return fetch(response.url).then(checkResponse)
  }

  if (!response.ok) {
    response.message = `Error while executing request: ${response.url}. Status: ${response.status}`
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

const toJson = (response) => {
  return checkResponse(response).then((r) => r.json())
}

const toText = (response) => {
  return checkResponse(response).then((r) => r.text())
}

export const downloadBinary = (url) =>
  securedFetch(url)
    .then(checkResponse)
    .then(
      (response) =>
        new Promise((resolve, reject) => {
          response
            .blob()
            .then((blob) => resolve(blob, response.headers.get('content-type')))
            .catch(reject)
        })
    )

export const downloadLogs = (params) =>
  downloadBinary(`/api/v1/artifacts/${params.repoName}/${params.buildId}/logs`)

export const downloadTestReports = (params) =>
  downloadBinary(
    `/api/v1/artifacts/${params.repoName}/${params.buildId}/test-results`
  )

export const fetchAllRepositories = () =>
  securedFetch(`/api/v1/repositories`).then(toJson)

export const fetchBuildDetails = (_, params) =>
  securedFetch(
    `/api/v1/builds/${params.repoName},${params.timestamp},${params.buildId}`
  ).then(toJson)

export const fetchBuilds = (_, params) => {
  const query = Object.keys(params)
    .filter((k) => k !== 'repoName' && params[k] != null)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

  let url = `/api/v1/builds/${params.repoName}?${query}`
  return securedFetch(url).then(toJson)
}

export const fetchJenkinsHosts = () =>
  securedFetch('/api/v1/jenkins').then(toJson)

export const fetchLogs = (_, { jenkinsId, jobName }) =>
  securedFetch(
    `/api/v1/jenkins/${encodeURIComponent(jenkinsId)}/jobs/${jobName}/logs`
  ).then(toText)

export const fetchMineRepositories = () =>
  securedFetch(`/api/v1/repositories/mine`).then(toJson)

export const queryJobs = (_, { jenkinsId }) =>
  securedFetch(`/api/v1/jenkins/${jenkinsId}/jobs`).then(toJson)

export const triggerJob = (body) => {
  let headers = { 'Content-Type': 'application/json' }
  return securedFetch(`/api/v1/builds/`, {
    method: 'POST',
    body: body,
    headers: headers
  })
}
