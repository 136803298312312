import { Repository } from '../../types'

type Repos = Repository[]

const filterRepos = (repos: Repos = [], searchTerm: string): Repos => {
  const term = searchTerm.trim().toLowerCase()

  if (!term) return repos

  const tokens = term
    .split(' ')
    .map((str) => str.trim())
    .filter(Boolean)

  return repos.filter(({ name: repoName }) => {
    const str = repoName.toLowerCase().trim()
    for (const token of tokens) {
      if (!str.includes(token)) return false
    }
    return true
  })
}

export default filterRepos
