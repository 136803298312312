import React from 'react'

const HomePage = (props) => {
  return (
    <div data-tdp-list>
      <div className='flex justify-center'>
        <div>
          <h1>Hello {props.username}</h1>
          <h1>Welcome to Tadpole</h1>
          <br />
          <p className='max-w-xl'>
            Is your service missing? Onboarding onto Tadpole is tied to
            onboarding onto BOB the Builder. To get started in your repo, check
            out the{' '}
            <a
              href='https://docs.build.eng.toasttab.com/html/index.html'
              target='_blank'
              rel='noreferrer'
            >
              Build Documentation.
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default HomePage
