import * as React from 'react'
import { useQuery } from 'react-query'
import { Alert } from '@toasttab/buffet-pui-alerts'

// This component is temporary as we are moving to github on-prem and github outtages will hopefully become a problem of the past

export async function fetchGitStatus() {
  const url = 'https://www.githubstatus.com/api/v2/components.json'
  return await fetch(url)
}

export const checkResponse = (response) => {
  if (response.redirected && !response.ok) {
    return fetch(response.url).then(checkResponse)
  }

  if (!response.ok) {
    response.message = `Error while executing request: ${response.url}. Status: ${response.status}`
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

const gitStatusJson = (response) => {
  return checkResponse(response).then((r) => r.json())
}

const RELEVANT_COMPONENTS = [
  'Git Operations',
  'API Requests',
  'Webhooks',
  'Pull Requests',
  'Actions',
  'Packages'
]

const isRelevantComponentAffected = (components) => {
  for (let component of components) {
    if (
      RELEVANT_COMPONENTS.includes(component.name) &&
      component.status !== 'operational'
    ) {
      return true
    }
  }
  return false
}

export const fetchGithubStatus = () => fetchGitStatus().then(gitStatusJson)

export const GitStatusIndicator = () => {
  const {
    isLoading,
    error,
    data: gitStatus
  } = useQuery(['fetch-github-status'], () => fetchGithubStatus(), {
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true
  })

  if (isLoading) {
    return <></>
  }

  if (error) {
    return <></>
  }

  if (isRelevantComponentAffected(gitStatus.components)) {
    return (
      <Alert outlined variant='error' className='fixed top-2 right-2'>
        <a
          className='inline-link-inherit'
          href='https://www.githubstatus.com/'
          rel='noopener'
          target='blank'
        >
          Github status
        </a>{' '}
        may affect builds!
      </Alert>
    )
  } else {
    return <></>
  }
}
