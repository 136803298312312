import * as React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

export default function Loading() {
  return (
    <div className='relative flex-grow'>
      <div className='pin-center'>
        <MerryGoRound />
      </div>
    </div>
  )
}
