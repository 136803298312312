/* eslint-disable no-undef */
import '@babel/polyfill'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import App from '../packages/RepositoryList/app/App'

import './main.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  rootComponent: App,
  // because this spa loads other spas, its css scope is carefully managed
  // to avoid wrapping its scope around its children, which can cause subtle tailwindcss bugs
  cssScope: '',
  // @ts-ignore
  domElementGetter: () => document.getElementById('container')
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
