import { Link, useHistory, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import cx from 'classnames'
import { SearchInput } from '@toasttab/buffet-pui-text-input'
import { TextToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  AddCircleIcon,
  CancelIcon,
  StarFilledIcon,
  StarIcon
} from '@toasttab/buffet-pui-icons'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { ListItem, MenuDropdown } from '@toasttab/buffet-pui-dropdowns'

import { fetchAllRepositories, fetchMineRepositories } from '../../queries'
import Loading from '../Loading'
import { FrogspawnRouterParams, Repository } from '../../types'
import PageRegistry from '../../PageRegistry'
import filterRepos from './filterRepos'
import { useLocalStorage } from 'react-use'
import { AutoSizer, List, CellMeasurer, CellMeasurerCache, ListRowProps } from 'react-virtualized'

interface RepositoryItemProps {
  repository: Repository
  selected: boolean
  view: string
  isFavorite: boolean
  toggleFavorite: () => void
}

const cellMeasurerCache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 40
})

const RepositoryItem = ({
  repository,
  selected,
  view = PageRegistry[0].pathName,
  isFavorite,
  toggleFavorite
}: RepositoryItemProps) => (
  <Link
    className={cx(
      'block type-subhead rounded-lg group',
      'py-3 md:py-2 px-3 -mb-px outline-none focus-visible:shadow-focus-inset',
      {
        'bg-brand-0 hover:bg-brand-10 text-brand-75 font-semibold': selected,
        'hover:bg-gray-0 text-default': !selected
      }
    )}
    to={`/${repository.name}/${view}/`}
  >
    <div className={cx('flex items-start gap-1 justify-between relative')}>
      <div
        className='flex-shrink break-words'
        style={{ wordBreak: 'break-word' }}
      >
        {repository.name}
      </div>
      <div
        onClick={toggleFavorite}
        className={cx(
          'flex items-center justify-center gap-0 h-5 flex-none',
          selected ? 'text-brand-75' : 'text-secondary'
        )}
      >
        {isFavorite ? (
          <StarFilledIcon
            className={cx('inline')}
            size='xs'
            aria-label='Favorite'
          />
        ) : (
          <>
            <div className={cx('group-hover:hidden w-4')} />
            <StarIcon
              className={cx('hidden group-hover:inline')}
              size='xs'
              aria-label='Add to favorite'
            />
          </>
        )}
      </div>
    </div>
  </Link>
)

const RepositoryList = ({ myRepos }: { myRepos: boolean }) => {
  cellMeasurerCache.clearAll()
  const {repoName, view} = useParams<FrogspawnRouterParams>()
  const [searchTerm, setSearchTerm] = React.useState('')
  const {data, error, isLoading} = useQuery<Array<Repository>>(
    [myRepos ? 'fetchMineRepositories' : 'fetchAllRepositories'],
    myRepos ? fetchMineRepositories : fetchAllRepositories
  )

  const [favorites, setFavorites] = useLocalStorage<Record<string, boolean>>(
    'favorite-repos',
    {}
  )
  const isRepoFavorite = (repoName: string) => !!favorites?.[repoName]
  const toggleFavorite = (repoName: string) => {
    let newFavorites = favorites ?? {}
    if (newFavorites[repoName]) {
      newFavorites[repoName] = !newFavorites[repoName]
    } else {
      newFavorites[repoName] = true
    }
    setFavorites(newFavorites)
  }

  if (isLoading) {
    return <Loading/>
  }

  if (error) {
    return <p>An error happened: {JSON.stringify(error)}</p>
  }

  const listItemsPreSort = filterRepos(data, searchTerm)
  const favoriteListItems = listItemsPreSort.filter((a) =>
    isRepoFavorite(a.name)
  )
  const notFavoriteListItems = listItemsPreSort.filter(
    (a) => !isRepoFavorite(a.name)
  )
  const listItems = [...favoriteListItems, ...notFavoriteListItems]

  const Row = ({ index, style, key, parent }: ListRowProps) => (
    <CellMeasurer
      cache={cellMeasurerCache}
      parent={parent}
      key={key}
      columnIndex={0}
      rowIndex={index}
    >
      <div style={style}>
        <RepositoryItem
          key={`${listItems[index].name}-${listItems[index].id}`}
          view={view}
          repository={listItems[index]}
          selected={listItems[index].name === repoName}
          isFavorite={isRepoFavorite(listItems[index].name)}
          toggleFavorite={() => toggleFavorite(listItems[index].name)}
        />
      </div>
    </CellMeasurer>
  );

  return (
    <div className='pt-4 mx-2 mt-4 border-t h-4/5'>
      <SearchInput
        placeholder='Filter repos...'
        containerClassName='mb-3'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        suffix={
          searchTerm && (
            <IconButton
              icon={<CancelIcon aria-label='Clear search'/>}
              textClassName='text-gray-50 hover:text-gray-75 focus:text-gray-75'
              contained
              onClick={() => {
                setSearchTerm('')
              }}
            />
          )
        }
      />
      {!listItems || listItems.length === 0 ? (
        <p className='p-2'>No repositories found.</p>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              rowCount={listItems.length}
              rowHeight={cellMeasurerCache.rowHeight}
              rowRenderer={Row}
              deferredMeasurementCache={cellMeasurerCache}
            />
          )}
        </AutoSizer>
      )}
    </div>
  )
}

const MigrateAndCreateServicePopup = () => {
  const history = useHistory()
  return (
    <MenuDropdown
      renderToggle={(props) => (
        <IconButton
          {...props}
          contained
          className='text-success'
          icon={
            <AddCircleIcon size='md' aria-label='Repository actions menu'/>
          }
        />
      )}
    >
      <ListItem
        label='Create a new repository'
        onClick={() => history.push('/create')}
      />
      <ListItem
        label='Add BoB webhook'
        onClick={() => history.push('/migrate')}
      />
    </MenuDropdown>
  )
}

const RepositoryListContainer = () => {
  const size = useScreenSize()
  const [myRepos, setAllRepos] = useState(false)
  return (
    <div data-tdp-list>
      <div
        style={{ width: size >= ScreenSize.MD ? '13rem' : '100%' }}
        className={cx('flex flex-col h-screen overflow-visible bg-white', {
          'fixed z-20 border-r mt-16': size >= ScreenSize.MD
        })}
      >
        <div className='flex flex-row items-center justify-between flex-none pl-2 mb-2 h-14'>
          <h3 className='font-bold type-headline-5 text-default'>
            Repositories
          </h3>
          <div>
            <MigrateAndCreateServicePopup />
          </div>
        </div>

        <TextToggleSwitch
          containerClassName='w-32 ml-2 flex-none'
          isActive={myRepos}
          labels={['All', 'Mine']}
          onChange={() => setAllRepos(!myRepos)}
        />
        <RepositoryList myRepos={myRepos} />
      </div>
    </div>
  )
}

export default RepositoryListContainer
